<template>
  <main>
    <div class="header">
      <router-link to="/post" class="back">
        <h1 class="chevron">&lt;</h1>
      </router-link>
    </div>
    <div class="content">
      <h2 class="title">{{title}}</h2>
      <p class="postdetail">&nbsp;&nbsp;&nbsp;&nbsp;{{text}}</p>
      <div class="footer">
        <p>团队敬上</p>
        <p>{{date}}</p>
        <p><a target="_blank" style="color: white" href="https://t.me/coinearn_org">TG官方客服</a></p>
      </div>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>
<script>
export default {
  name: 'PostDetail',
  data() {
    return {
      loading:false,
      date: this.$route.params.date,
      title: this.$route.params.title,
      text: this.$route.params.text,
    }
  },
  mounted(){
  },
  created() {
  },
};
</script>

<style scoped>

.header .back {
  text-decoration: none;
  color: #bbb;
}

.header .title {
  flex-grow: 1;
  text-align: center;
}

.content {
  padding: 20px;
  text-align: center;
  margin-top: 1rem;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
}

title {
  font-size: 1rem;
  margin-bottom: 20px;
}

p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.footer {
  margin-top: 30px;
  text-align: right;
}

.navbar div {
  flex: 1;
  text-align: center;
  padding: 10px 0;
}
.postdetail{
  text-align: left;
}
.chevron{
  padding-left: 1rem;
  margin: 0;
}

</style>
