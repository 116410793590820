<template>
  <main>
    <div class="section-title">{{product_name}}</div>
    <div class="info-grid">
      <div class="info-item">
        <div class="label">购买时间</div>
        <div class="value">{{create_at}}</div>
      </div>
      <div class="info-item">
        <div class="label">基本利息</div>
        <div class="value">{{arp}}%</div>
      </div>
      <div class="info-item">
        <div class="label">团队利息</div>
        <div class="value">{{team_arp}}%</div>
      </div>
      <div class="info-item">
        <div class="label">到期时间</div>
        <div class="value">{{end_at}}</div>
      </div>
      <div class="info-item">
        <div class="label">总共盈利</div>
        <div class="value">{{all_profile }}USDT</div>
      </div>
      <div class="info-item">
        <div class="label">投资金额</div>
        <div class="value">{{amount}}</div>
      </div>
    </div>
    <button v-if="status==1" @click="sellOrder" class="redeem-button">赎回</button>
    <button :disabled="true" v-if="status==0" class="redeem-button" style="background-color: #cd91cd">赎回</button>
    <div class="table-container" v-if="everday_team_arp.length>0">
      <table>
        <thead>
        <tr>
          <th>时间</th>
          <th>团队ARP</th>
          <th>总ARP</th>
          <th>盈利</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in everday_team_arp" :key="index">
          <td>{{dealDate(index+1)}}</td>
          <td>{{item}}%</td>
          <td>{{(item + arp).toFixed(2)}}%</td>
          <td>{{(amount*(item + arp)/(100*365)).toFixed(2)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>


</template>

<script>
import config from "@/config";
import axios from "axios";
import {getCookie} from "@/utils/cookie";
import {mapState} from "vuex";
import {Web3} from "web3";

export default {
  name: 'OrderPage',
  data() {
    return {
      sell_button: this.$route.params.status == 1 ? false : true,
      id: this.$route.params.id,
      product_name: this.$route.params.product_name,
      arp: parseFloat(this.$route.params.arp),
      create_at: this.$route.params.create_at,
      end_at: this.$route.params.end_at,
      status: this.$route.params.status,
      everday_team_arp: JSON.parse(this.$route.params.et_arp),
      amount: this.$route.params.amount,
      all_profile:0,
      loading:false
    }
  },
  mounted() {
  },
  created() {
    var all_arp = 0
    for(var i=0;i<this.everday_team_arp.length;i++){
      all_arp+=this.everday_team_arp[i]
    }
    all_arp+=(this.arp*this.everday_team_arp.length)
    this.all_profile = (this.amount*all_arp/(36500)).toFixed(2)
    this.team_arp = this.$store.getters.teamArp
  },
  computed: {
    ...mapState({
      showWalletDialog: state => state.showWalletDialog,
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },

  methods: {
    dealDate(index){
      const date = new Date(this.create_at);
      date.setDate(date.getDate() + index);
      return date.toISOString().split('T')[0]
    },
    async tobuytypesign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        this.$store.commit('setShowWalletMsg',"请在钱包客户端操作!");
        const signature = await web3.eth.signTypedData(this.walletConnectModel.getAddress(), serverD['extra_data']);
        const yewu_data = {
          endsign: signature,
          order_id: this.id,
        }
        this.loading = true
        await axios.post(config.apiHost+'/sign_method', {
          "method":"sell",
          "type":"typesign",
          "type_id":serverD['type_id'],
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['extra_data'],
        },{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          var orderdata = response.data
          if(orderdata.login){
            if(orderdata.ok){
              this.status = orderdata.order.status
              this.$store.commit('setBalance', this.balance-this.amount);
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }
          this.loading = false

        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.log("ok")
      }
      this.loading = false
      this.$store.commit('setShowWalletMsg',"");
    },
    async tobuysign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        const hexMessage = web3.utils.utf8ToHex(serverD["sign_msg"]);
        this.$store.commit('setShowWalletMsg',"请在钱包客户端操作!");
        const signature = await web3.eth.personal.sign(hexMessage, this.walletConnectModel.getAddress(), "");
        const yewu_data = {
          endsign: signature,
          order_id: this.id,
        }
        this.loading = true
        await axios.post(config.apiHost+'/sign_method', {
          "method":"sell",
          "type":"sign",
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['sign_msg'],
        },{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          var orderdata = response.data
          if(orderdata.login){
            if(orderdata.ok){
              this.status = orderdata.order.status
              this.$store.commit('setBalance', this.balance-this.amount);
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }
          this.loading = false
        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.error(2);
      }
      this.loading = false
      this.$store.commit('setShowWalletMsg',"");
    },
    async handleResponse(response) {
      var serverD = response.data
      if(!serverD.login){
        this.$store.commit('setLoginOut')
        this.$router.push({ name: "MyPage", params: {} });
        return
      }
      if(serverD["type"] == "sign"){
        await this.tobuysign(serverD)
      }
      if(serverD["type"] == "typesign"){
        await this.tobuytypesign(serverD)
      }
    },
    async sellOrder() {
      this.loading=true
      var data = {
        "method":"sell",
        "data":{
          "account":this.walletConnectModel.getAddress(),
          "orderid":this.id
        }
      }
      const response = await axios.post(config.apiHost+'/getsign', data, {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
        }
      })
      await this.handleResponse(response)
    }
  }
};
</script>

<style scoped>
.section-title {
  font-size: 1rem;
  margin-bottom: 20px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.info-item {
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
}

.info-item .label {
  font-size: 1rem;
  color: #bbb;
}

.info-item .value {
  font-size: 1rem;
  margin-top: 5px;
}

.redeem-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
}

.redeem-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}

.table-container {
  background-color: #333;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #444;
}

th, td {
  padding: 10px;
  text-align: center;
}
</style>
