

<template>
  <div id="app">
    <div class="container">
      <header class="header">
        理财宝
      </header>
      <router-view/>
    </div>
    <footer class="footerdiv">
      <router-link to="/" class="footer-item nav-item" active-class="active">
        <div class="icon">🏠</div>
        <div class="label">产品</div>
      </router-link>
      <router-link  to="/order"  class="footer-item nav-item" active-class="active">
        <div class="icon">🏵️</div> <!-- 使用文件图标表示订单 -->
        <div class="label">订单</div>
      </router-link>
      <router-link to="/my" class="footer-item nav-item" active-class="active">
        <div class="icon">🍄</div>
        <div class="label">我的</div>
      </router-link>
    </footer>
  </div>


  <div class="showalletdiv" v-if="showWalletDialog">
    <div id="overlay" class="overlay"></div>
    <div id="dialog" class="showallet">
      <div class="showallet-content">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{showWalletMsg}}</p>
      </div>
      <div class="showallet-actions">
        <button class="confirm-button" @click="okclose()">OK</button>
      </div>
    </div>
  </div>


</template>



<script>


import {mapState} from "vuex";

export default {
  name: 'App',
  data() {
    return {
      loading: true,
      data: null,
      isconnect:false,
    };
  },
  created() {
    this.listenWallet()
    this.isconnect = this.$store.state.walletConnectModel.getIsConnectedState()
  },
  computed: {
    ...mapState({
      showWalletDialog: state => state.showWalletDialog,
      showWalletMsg:state => state.showWalletMsg
    }),
  },
  methods: {
    okclose(){
      if(this.showWalletDialog){
        this.$store.commit('setShowWalletMsg', "");
      }
    },
    async connectWallet() {
      console.log(this.$store.state.walletConnectModel.getIsConnectedState())
    },
    async listenWallet(){
      try {
        await this.$store.dispatch('listenWallet');
        console.log('listenWallet!');
      } catch (error) {
        console.error('Failed to listenWallet:', error);
      }
    }
  }
};
</script>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #1a1a1a;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

header .title {
  font-size: 1rem;
  font-weight: bold;
}


main {
  flex: 1;
  padding: 80px 20px 5rem;
  background-color: #000000;
}
footer {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  text-decoration: none;

  flex-grow: 1;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 10px;
  background-color: #2e2e2e;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-item.active {
  background: #ff00ff;
}

.footer-item .icon {
  font-size: 2rem;
  margin-bottom: 5px
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  cursor: pointer;
}



.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.showalletdiv{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}


.showallet {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2000;

}
.showallet-header span {
  font-size: 1rem;
  font-weight: bold;
}
.showallet-actions {
  display: block;
  text-align: center;
  justify-content: space-between;
}

.confirm-button {
  background-color: #ff00ff;
  color: white;
}
.confirm-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.confirm-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
}


/* 全局样式 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black; /* 设置背景为黑色 */
}

#app {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100%;
}

.container {
  width: 600px; /* PC端固定内容宽度 */
  background-color: black; /* 内容背景为白色 */
  height: 100%;
}

/* 媒体查询：当屏幕宽度小于等于 800px 时应用 */
@media (max-width: 600px) {
  .container {
    width: 100%; /* 手机端宽度为屏幕宽度 */
  }
}

.footerdiv {
  width: 600px; /* PC端固定内容宽度 */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/* 媒体查询：当屏幕宽度小于等于 800px 时应用 */
@media (max-width: 600px) {
  .footerdiv {
    width: 100%; /* 手机端宽度为屏幕宽度 */
  }
}
</style>
