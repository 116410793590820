// store.js 或 store/index.js
import { createStore } from 'vuex';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/vue'
import {Web3} from "web3";
import {getCookie, setCookie} from "@/utils/cookie";
import axios from "axios";
import config from "@/config";
// import {stat} from "@babel/core/lib/gensync-utils/fs";
// import axios from "axios";
// import config from "@/config";
// 1. Get projectId at https://cloud.walletconnect.com


const projectId = '7e4f2aef2fc4a112254328be6ca20674'
// 2. Set chains
const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
//https://mainnet.infura.io/v3/ac3527b474d043e0b0b0bac6f33a6cd1'
}

// 3. Create your application's metadata object
const metadata = {
    name: '理财宝',
    description: '专注于去中心化链上投资',
    url: 'https://coinearn.org', // url must match your domain & subdomain
    icons: ['https://coinearn.org/favicon.ico']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
const modal = createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true // Optional - false as default
})
export default createStore({
    state: {
        walletConnected: false,
        walletLogin:false,
        accountAddress:"",
        walletConnectModel: modal,
        team_arp:0,
        balance:0,
        level:0,
        account_info:null,
        showWalletDialog:false,
        showWalletMsg:""
    },
    mutations: {
        setLoginOut(state){
            state.walletLogin=false
        },
        setAccountInfo(state, data) {
            state.account_info = data;
            state.balance = data.balance
            state.level = data.level
            state.team_arp = parseFloat(data.team_arp)
        },
        setBalance(state,b){
            state.balance = b
        },
        setShowWalletMsg(state,msg){
            if(msg == ""){
                state.showWalletDialog = false
            }else{
                state.showWalletMsg = msg
                state.showWalletDialog = true
            }
        }
    },
    actions: {
        async listenWallet({ state }){
            async function handleChange({isConnected }) {
                state.walletConnected = isConnected
                if(!isConnected){
                    state.walletLogin = false
                }else{
                    modal.setIsConnected(true)
                    if(getCookie("account").toLowerCase()  == modal.getAddress().toLowerCase()){
                        state.accountAddress = modal.getAddress().toLowerCase()
                        state.walletLogin = true
                        axios.get(config.apiHost+'/account', {
                            headers: {
                                'Content-Type': 'application/json',
                                'loginsign':getCookie("signature"),
                                'Loginmsg':getCookie("login_msg"),
                                'invitefrom':getCookie("invite_from")
                            }
                        }).then(response => {
                            var user = response.data
                            if(user.login){
                                state.account_info = response.data;
                                state.balance = response.data.balance
                                state.level = response.data.level
                                state.team_arp = response.data.team_arp
                            }else{
                                state.walletLogin=false
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                        return
                    }else{
                        state.walletLogin = false
                    }
                }
            }
            modal.subscribeProvider(handleChange)
            modal.subscribeEvents(event => console.log(event))
            console.log("开始监听",modal)
        },
        async walletLogin({ state }){
            async function towallettypesign(serverD){
                try {
                    const provider = await modal.getWalletProvider();
                    const web3 = new Web3(provider);
                    const signature = await web3.eth.signTypedData(modal.getAddress(), serverD['extra_data']);
                    setCookie("signature",signature,1)
                    setCookie("login_msg",serverD["sign_msg"],1)
                    setCookie("account",modal.getAddress().toLowerCase(),1)
                    state.accountAddress = modal.getAddress().toLowerCase()
                    state.walletLogin = true
                    await axios.post(config.apiHost+'/sign_method', {
                        "method":"login",
                        "type":"typesign",
                        "type_id":serverD['type_id'],
                        "account":modal.getAddress(),
                        "data":serverD['data'],
                        "extra_data":serverD['extra_data'],
                        "signature":signature,
                    },{
                        headers: {
                            'Content-Type': 'application/json',
                            'loginsign':signature,
                            'Loginmsg':serverD["sign_msg"],
                            'invitefrom':getCookie("invite_from")
                        }
                    }).then(response => {
                        var user = response.data
                        if(user.login){
                            state.account_info = response.data;
                            state.balance = response.data.balance
                            state.level = response.data.level
                            state.team_arp = response.data.team_arp
                        }else{
                            state.walletLogin=false
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                } catch (error) {
                    state.walletLogin = false
                } finally {
                    console.error(2);
                }
            }

            async function towalletsign(serverD){
                try {
                    const provider = await modal.getWalletProvider();
                    const web3 = new Web3(provider);
                    const hexMessage = web3.utils.utf8ToHex(serverD["sign_msg"]);
                    const signature = await web3.eth.personal.sign(hexMessage, modal.getAddress(), "");
                    const recoveredAddress = web3.eth.accounts.recover(hexMessage, signature);
                    setCookie("signature",signature,1)
                    setCookie("login_msg",serverD["sign_msg"],1)
                    setCookie("account",modal.getAddress().toLowerCase(),1)
                    state.accountAddress = recoveredAddress
                    state.walletLogin = true

                    await axios.post(config.apiHost+'/sign_method', {
                        "method":"login",
                        "type":"sign",
                        "account":modal.getAddress(),
                        "data":serverD['data'],
                        "extra_data":serverD['extra_data'],
                        "signature":signature,
                    },{
                        headers: {
                            'Content-Type': 'application/json',
                            'loginsign':signature,
                            'Loginmsg':serverD["sign_msg"],
                            'invitefrom':getCookie("invite_from")
                        }
                    }).then(response => {
                        var user = response.data
                        if(user.login){
                            state.account_info = response.data;
                            state.balance = response.data.balance
                            state.level = response.data.level
                            state.team_arp = response.data.team_arp
                        }else{
                            state.walletLogin=false
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                } catch (error) {
                    state.walletLogin = false
                } finally {
                    console.error(2);
                }
            }

            async function handleResponse(response) {
                console.log(response)
                var serverD = response.data
                if(!serverD.login){
                    state.walletLogin=false
                }
                if(serverD["type"] == "sign"){
                    await towalletsign(serverD)
                }
                if(serverD["type"] == "typesign"){
                    await towallettypesign(serverD)
                }
            }
            var data = {
                "method":"login",
                "account":modal.getAddress(),
                "data":{
                    "datetime":Date.parse(new Date())
                }
            }
            const response = await axios.post(config.apiHost+'/getsign', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            await handleResponse(response)
        }
    },
    getters: {
        isWalletConnected(state) {
            return state.walletConnectModel.getIsConnectedState();
        },
        teamArp(state) {
            return state.team_arp;
        },
        getBalance(state) {
            return state.balance;
        }

    },
});